export const sizes = {
  borderRadius: {
    normal: '6px',
    big: '9px'
  },
  borderWidth: {
    thin: '1px',
    medium: '2px',
    thick: '4px',
    fat: '6px'
  },
  padding: {
    small: '.2em',
    medium: '0.3em',
    big: '1em',
    huge: '22px'
  },
  icon: {
    big: '64px',
    medium: '48px',
    small: '32px',
    tiny: '20px'
  },
  fontSizes: {
    small: '0.8rem',
    medium: '1rem',
    big: '1.25rem',
    huge: '1.5rem',
    massive: '2rem'
  }
};
