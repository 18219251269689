import { CustomerModel } from '../types/models/customer-model';
import { SharingInfoState } from '../types/models/sharing-info-state';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const WS_GET_MY_QUEUES = 'qmob/get-my-queues';
export const WS_GET_QUEUE_DETAIL = 'qmob/get-queue-detail';
export const WS_ENTER_PROVIDER_CODE = 'qmob/enter-provider-code';
export const WS_REMOVE_TICKET = 'qmob/remove-ticket';
export const WS_ENTER_PIN = 'qmob/enter-ticket-pin';
export const WS_SETUP_PUSH_NOTIFICATIONS = 'qmob/setup-push-notifications';
export const WS_SAVE_CUSTOMER_PROFILE = 'qmob/save-customer-profile';
export const WS_REMOTE_CHECKIN = 'qmob/checkin-to-queue';

export interface CustomerProfileUpdateModel {
  phoneNumber?: string;
  fullName?: string;
}

export interface WSRequestMessage {
  correlationId: number;
}

export interface WSQueueDetail extends WSRequestMessage {
  cmd: typeof WS_GET_QUEUE_DETAIL;
  queueId: string;
}
export interface WSGetMyQueues extends WSRequestMessage {
  cmd: typeof WS_GET_MY_QUEUES;
}

export interface WSEnterProviderCode extends WSRequestMessage {
  cmd: typeof WS_ENTER_PROVIDER_CODE;
  code: string;
}

export interface WSEnterTicketPin extends WSRequestMessage {
  cmd: typeof WS_ENTER_PIN;
  queueId: string;
  pin: string;
}

export interface WSRemoveTicket extends WSRequestMessage {
  cmd: typeof WS_REMOVE_TICKET;
  queueId: string;
}
export interface WSRemoteCheckin extends WSRequestMessage {
  cmd: typeof WS_REMOTE_CHECKIN;
  queueId: string;
  sharingInfo: SharingInfoState;
}
export interface WSSaveCustomerProfile extends WSRequestMessage {
  cmd: typeof WS_SAVE_CUSTOMER_PROFILE;
  customerProfile: CustomerProfileUpdateModel;
}

export type WSMessage =
  | WSQueueDetail
  | WSGetMyQueues
  | WSEnterProviderCode
  | WSEnterTicketPin
  | WSSaveCustomerProfile
  | WSRemoveTicket
  | WSRemoteCheckin;

function addCorrelationId() {
  return {
    correlationId: +new Date()
  };
}

export const WSMessages = {
  getQueueDetail(queueId: string): WSQueueDetail {
    return { cmd: WS_GET_QUEUE_DETAIL, queueId, ...addCorrelationId() };
  },
  getMyQueues(): WSGetMyQueues {
    return { cmd: WS_GET_MY_QUEUES, ...addCorrelationId() };
  },
  enterProviderCode(code: string): WSEnterProviderCode {
    return { cmd: WS_ENTER_PROVIDER_CODE, code, ...addCorrelationId() };
  },
  setupPushNotifications(data: any) {
    return { cmd: WS_SETUP_PUSH_NOTIFICATIONS, ...data };
  },
  removeTicket(queueId: string): WSRemoveTicket {
    return {
      cmd: WS_REMOVE_TICKET,
      queueId,
      ...addCorrelationId()
    };
  },
  enterPIN(queueId: string, pin: string): WSEnterTicketPin {
    return { cmd: WS_ENTER_PIN, queueId, pin, ...addCorrelationId() };
  },

  remoteCheckin(
    queueId: string,
    sharingInfo: SharingInfoState
  ): WSRemoteCheckin {
    return {
      cmd: WS_REMOTE_CHECKIN,
      queueId,
      sharingInfo,
      ...addCorrelationId()
    };
  },

  saveCustomerProfile(
    customerProfile: CustomerProfileUpdateModel
  ): WSSaveCustomerProfile {
    return {
      cmd: WS_SAVE_CUSTOMER_PROFILE,
      customerProfile,
      ...addCorrelationId()
    };
  }
};

export const WS_REMOTE_CHECKIN_RESPONSE = 'qmob/checkin-to-queue-response';

export interface WSRemoteCheckinResponse {
  cmd: typeof WS_REMOTE_CHECKIN_RESPONSE;
  result: 'ok' | 'error';
  correlationId: number;
  errorCode: 'unknownError' | null | undefined;
}

export const WS_REMOVE_TICKET_RESPONSE = 'qmob/remove-ticket-response';

export interface WSRemoveTicketResponse {
  cmd: typeof WS_REMOVE_TICKET_RESPONSE;
  result: 'ok' | 'error';
  correlationId: number;
  errorCode: 'unknownError' | null | undefined;
  origin: 'qsrv' | null | undefined;
}

export const WS_SAVE_CUSTOMER_PROFILE_RESPONSE =
  'qmob/save-customer-profile-response';

export interface WSSaveCustomerProfileResponse {
  cmd: typeof WS_SAVE_CUSTOMER_PROFILE_RESPONSE;
  result: 'ok' | 'error';
  errorCode:
    | 'unknownError'
    | 'invalidPhone'
    | 'invalidEmail'
    | 'fullNameMinLength'
    | 'fullNameMaxLength'
    | null
    | undefined;
  correlationId: number;
  customer: CustomerModel | null | undefined;
}

export const WS_ENTER_PROVIDER_CODE_RESPONSE =
  'qmob/enter-provider-code-response';

export interface WSEnterProviderCodeResponse {
  cmd: typeof WS_ENTER_PROVIDER_CODE_RESPONSE;
  result: 'ok' | 'error';
  errorCode: 'invalidCode' | null | undefined;
  correlationId: number;
}

export const WS_ENTER_PIN_RESPONSE = 'qmob/enter-ticket-pin-response';

export interface WSEnterPinResponse {
  cmd: typeof WS_ENTER_PIN_RESPONSE;
  result: 'ok' | 'error';
  errorCode: 'invalidCode' | null | undefined;
  correlationId: number;
}
