export const cs = {
  appName: 'NextinQ',
  enums: {
    sharingInfo: {
      fullName: 'Celé jméno',
      email: 'Email',
      phoneNumber: 'Telefon'
    },
    wsConnectionStatus: {
      connecting: 'probíhá připojování ...',
      error: 'Chyba spojení'
    }
  },
  buttons: {
    ok: 'Ok',
    signOut: 'Odhlásit se',
    enterPIN: 'Mám lístek',
    checkIn: 'Chci lístek',
    addProvider: 'Přidat poskyt.',
    next: 'Další',
    remoteCheckin: 'Zařadit se',
    updateProfile: 'Upravit profil',
    leaveQueue: 'Opustit frontu',
    saveProfile: 'Uložit profil'
  },
  successes: {
    userProfileUpdated: 'Váš profil byl uložen',
    providerCodeEntered: 'Poskytovatel byl přidán do Vašich služeb'
  },
  errors: {
    invalidEmail: 'Nesprávný formát emailu',
    invalidPhone: 'Nesprávný formát tel. čísla',
    fullNameMinLength: 'Jméno je příliš krátké',
    fullNameMaxLength: 'Jméno je příliš dlouhé (max. 30 znaků)',
    invalidCode: 'Nesprávný kód poskytovatele služby',
    invalidPIN: 'Nesprávný pin lístku',
    serverError: 'Neočekávaná chyba, zkuste prosím akci zopakovat',
    unknownError: 'Neočekávaná chyba, zkuste prosím akci zopakovat',
    invalidQueueId: 'Neočekávaná chyba, zkuste prosím akci zopakovat',
    queueClosed: 'Fronta je zavřená',
    errUserAlreadyChecked: 'Do této fronty jste již zařazen',
    errRemoteCheckinDisabled:
      'Poskytovatel nepovolil pro tuto frontu vzdálené přihlášení',
    errMaxCheckinExceeded:
      'Překročili jste povolený počet vzdálených přihlášení za den do této fronty',
    auth: {
      'Confirmation code cannot be empty': 'Zadejte potvrzovací kód',
      'Invalid verification code provided, please try again.':
        'Nesprávný potvrzovací kód',
      'Cannot reset password for the user as there is no registered/verified email or phone_number':
        'Nelze resetovat heslo pro neregistrovaného nebo neověřeného uživatele',
      'An account with the given phone_number already exists.':
        'Tento účet již existuje',
      'Network error': 'Chyba spojení k internetu',
      'Username cannot be empty': 'Zadejte prosím tel. číslo',
      'User does not exist.': 'Nesprávné tel. číslo',
      'null invocation failed due to configuration.':
        'Nesprávné tel. číslo nebo heslo',
      'Incorrect username or password.': 'Nesprávné tel. číslo nebo heslo',
      'Password cannot be empty': 'Zadejte prosím heslo',
      'Password did not conform with policy: Password not long enough':
        'Heslo musí mít nejméně 6 znaků',
      'Username/client id combination not found.': 'Nesprávné tel. číslo',
      "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6":
        'Heslo musí mít nejméně 6 znaků'
    }
  },
  screen: {
    registerQueue: {
      title: 'Kód poskytovatele'
    },
    registerTicket: {
      title: 'Zadejte PIN lístku'
    },
    userProfile: {
      title: 'Můj profil'
    },
    sharingInfo: {
      title: 'Zařadit se'
    },
    remoteCheckin: {
      title: 'Zařadit se'
    },
    leaveQueue: {
      title: 'Skutečně opustit frontu?',
      message1: 'Po opuštění fronty pozbývá lístek platnosti',
      message2:
        'Opuštěním fronty nám pomáháte urychlit odbavení ostatních zákazníků - děkujeme.'
    },
    error: {
      title: 'Chyba připojení',
      info: 'Bohužel se nemůžeme spojit s naším serverem',
      actionInfo: 'Zkontrolujte připojení k internetu a zkuste to znovu',
      tryAgain: 'Zkusit znovu'
    },
    sharingInfoScreen: {
      infoText1: 'Vyberte prosím údaje, které chcete zobrazit poskytovateli',
      infoText2: 'Můžete tím urychlit Vaše odbavení.',
      completeUserProfileInfo:
        'Abyste mohli sdílet všechny údaje, dokončete prosím nastavení Vašeho profilu',
      gotoProfile: 'Upravit můj profil'
    },
    remoteCheckinScreen: {
      summary: 'Zařadit se do fronty na tuto službu',
      sharingInfoTitle: 'souhlasíte s poskytnutím těchto Vašich údajů:',
      noSharedItems: 'Neposkytovat této službě žádné údaje - anonymní zařazení'
    }
  },
  titles: {
    myQueues: 'Moje služby',
    checkedQueues: 'Moje zařazení'
  },
  statuses: {
    queueStatus: {
      open: 'Otevřeno',
      closed: 'Zavřeno',
      disconnected: 'Odpojeno'
    },
    queueInfo: {
      servedNow: 'Jste na řadě, vstupte prosím',
      overdue: 'Už jste byl(a) volán(a), vstupte prosím',
      peopleInQueue: {
        smartCount:
          'Čeká tu jen 1 člověk |||| Čekají tu %{smart_count} lidé |||| Čeká tu %{smart_count} lidí',
        zero: 'Čekárna je prázdná'
      },
      peopleInFrontOfMe: {
        zero: 'Jste další na řadě, vyčkejte na vyzvání prosím',
        smartCount:
          'Jen %{smart_count} člověk čeká před Vámi |||| Jen %{smart_count} lidé čekají před Vámi |||| %{smart_count} lidí čeká před Vámi'
      },
      onePersonInQueue: 'Čeká tu jen 1 člověk',
      queueEmpty: 'Čekárna je prázdná'
    }
  },
  components: {
    userProfile: {
      email: 'Email',
      fullName: 'Celé jméno',
      phoneNumber: 'Telefon'
    },
    dialCodeSelector: {
      selectCodeTitle: 'Vyberte předčíslí'
    },
    signUp: {
      title: 'Zaregistrujte se nyní',
      phoneInput: 'Vaše tel. číslo',
      passwordInput: 'Heslo (min 6 znaků)',
      signUpButton: 'Zaregistrovat se',
      signInLink: 'Přihlášení'
    },
    signIn: {
      title: 'Vítejte zpět',
      phoneInput: 'Vaše tel. číslo',
      passwordInput: 'Heslo (min 6 znaků)',
      signInButton: 'Přihlásit se',
      signUpLink: 'Registrace',
      forgotPasswordLink: 'Zapomenuté heslo?'
    },
    confirmSignUp: {
      title: 'Ověření tel. čísla',
      codeInput: 'Zadejte potvrzovací kód ze SMS',
      passwordInput: 'Heslo (min 6 znaků)',
      confirmSignUpButton: 'Potvrdit',
      signInLink: 'Zpět na přihlášení',
      resendCode: 'Znovu zaslat kód'
    },
    forgotPassword: {
      title: 'Zapomenuté heslo',
      phoneInput: 'Zadejte tel. číslo',
      codeInput: 'Zadejte potvrzovací kód ze SMS',
      passwordInput: 'Heslo (min 6 znaků)',
      send: 'Odeslat',
      signInLink: 'Zpět na přihlášení',
      resendCode: 'Znovu zaslat kód',
      changePassword: 'Změnit heslo',
      newPassword: 'Nové heslo'
    },
    errors: {
      auth: {
        'Username cannot be empty': 'Zadejte správné tel.číslo'
      }
    },
    userProfileButton: {
      myProfile: 'Můj profil'
    }
  }
};
