import styled, { css } from 'styled-components';
import { AddCircle, Save } from '@styled-icons/remix-line';
import { t } from '../../locales/i18n';
import { sizes } from '../../themes/sizes';
import { colors } from '../../themes/colors';
import { Link } from 'react-router-dom';

const color = colors.primaryDarker;

const baseContainer = css`
  display: grid;
  justify-items: center;
  cursor: pointer;
  color: ${color};
  font-size: ${sizes.fontSizes.big};
  border: 1px solid ${color};
  border-radius: ${sizes.borderRadius.normal};
  padding: ${sizes.padding.medium};
`;

const primaryContainer = css`
  background-color: ${color};
  color: ${colors.white};
  svg {
    fill: ${colors.white};
  }
`;

const Container = styled.section<{ $primary: boolean }>`
  ${baseContainer}
  ${(props) => props.$primary && primaryContainer}
`;

const LinkContainer = styled(Link)<{ $primary: boolean }>`
  ${baseContainer}
  ${(props) => props.$primary && primaryContainer}
`;

const iconSize = sizes.icon.small;

const AddIcon = styled(AddCircle)`
  width: ${iconSize};
  height: ${iconSize};
  fill: ${color};
`;

const SaveIcon = styled(Save)`
  width: ${iconSize};
  height: ${iconSize};
  fill: ${colors.primary};
`;

export type ActionIconName = 'add' | 'save';

export interface ActionItemProps {
  onClick?: () => void;
  to?: string;
  titleKey?: string;
  iconName?: ActionIconName;
  primary?: boolean;
}
export function ActionItem(props: ActionItemProps) {
  const { iconName, primary } = props;
  if (props.to) {
    return (
      <LinkContainer $primary={!!primary} to={props.to}>
        {iconName === 'add' && <AddIcon />}
        {iconName === 'save' && <SaveIcon />}
        {props.titleKey && <div>{t(props.titleKey)}</div>}
      </LinkContainer>
    );
  }
  return (
    <Container
      $primary={!!primary}
      onClick={() => props.onClick && props.onClick()}
    >
      {iconName === 'add' && <AddIcon />}
      {iconName === 'save' && <SaveIcon />}
      {props.titleKey && <div>{t(props.titleKey)}</div>}
    </Container>
  );
}
