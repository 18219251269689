import { StrictMode } from 'react';

import { QueueListPage } from './pages/queue-list-page';
import * as serviceWorkerRegistration from './service-worker-registration';
import { RecoilRoot } from 'recoil';
import {
  SuperTokensWrapper,
  getSuperTokensRoutesForReactRouterDom
} from 'supertokens-auth-react';
import * as reactRouterDom from 'react-router-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import * as ReactDOM from 'react-dom/client';
import { initSupertokens } from './supertokens';
import { SessionAuth } from 'supertokens-auth-react/recipe/session';
import { AppLayout } from './layouts/app-layout';
import { WSContextProvider } from './components/ws-context-provider';
import { EnterProviderCodePage } from './pages/enter-provider-code-page';
import { QueueDetailPage } from './pages/queue-detail/queue-detail-page';
import { EnterTicketPinPage } from './pages/enter-ticket-pin-page';
import { RemoteCheckinPage } from './pages/queue-detail/remote-checkin-page';
import { UserProfilePage } from './pages/user-profile-page';
import { GlobalStyle } from './themes/global-style';
import { SharingInfoPage } from './pages/queue-detail/sharing-info-page';
import { LeaveQueuePage } from './pages/queue-detail/leave-queue-page';
import { Toaster } from 'react-hot-toast';

initSupertokens();

serviceWorkerRegistration.register();

function Main() {
  return (
    <StrictMode>
      <RecoilRoot>
        <GlobalStyle />
        <WSContextProvider>
          <SuperTokensWrapper>
            <BrowserRouter>
              <Routes>
                {/*This renders the login UI on the /auth route*/}
                {getSuperTokensRoutesForReactRouterDom(reactRouterDom)}
                {/*Your app routes*/}
                <Route
                  path="/"
                  element={
                    <SessionAuth>
                      <AppLayout />
                    </SessionAuth>
                  }
                >
                  <Route index element={<QueueListPage />} />
                  <Route path="queues/:queueId" element={<QueueDetailPage />} />
                  <Route
                    path="queues/:queueId/enter-ticket-pin"
                    element={<EnterTicketPinPage />}
                  />
                  <Route
                    path="queues/:queueId/leave-queue"
                    element={<LeaveQueuePage />}
                  />
                  <Route
                    path="queues/:queueId/remote-checkin"
                    element={<RemoteCheckinPage />}
                  />
                  <Route
                    path="queues/:queueId/sharing-info"
                    element={<SharingInfoPage />}
                  />
                  <Route
                    path="enter-provider-code"
                    element={<EnterProviderCodePage />}
                  />
                  <Route path="user-profile" element={<UserProfilePage />} />
                </Route>
              </Routes>
            </BrowserRouter>
          </SuperTokensWrapper>
        </WSContextProvider>
      </RecoilRoot>
      <Toaster
        position="top-center"
        key="1"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: '',
          duration: 5000,
          style: {
            background: '#363636',
            color: '#fff'
          },

          // Default options for specific types
          success: {
            duration: 3000,
            theme: {
              primary: 'green',
              secondary: 'black'
            }
          }
        }}
      />
    </StrictMode>
  );
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(<Main />);
