import { createContext } from 'react';
import { WSMessage } from '../web-socket/messages';

export interface WSContextWrapper {
  sendWSMessage: (msg: WSMessage) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  subscribeMessage<T>(cmd: string, callback: (data: T) => void): any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  unsubscribeMessage(cmd: string): void;
  socketIsOpen: boolean;
}

const dummyWrapper: WSContextWrapper = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  sendWSMessage(msg) {},
  socketIsOpen: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  subscribeMessage: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  unsubscribeMessage: () => {}
};

export const WSContext = createContext<WSContextWrapper>(dummyWrapper);
