import { SharingInfoItem, SharingItemModel } from './sharing-info-item';
import styled from 'styled-components';
import { sizes } from '../../../themes/sizes';
import { useRecoilState } from 'recoil';
import { sharingItemsState } from '../../../atoms/sharing-items-state';

const Container = styled.section`
  display: grid;
  margin: ${sizes.padding.big} 0;
  h4 {
    font-family: OpenSans;
    margin-bottom: ${sizes.padding.big};
  }
`;

const ItemsPanel = styled.section`
  display: grid;
  gap: ${sizes.padding.big};
`;
export interface SharingInfoItemsProps {
  panelTitle?: string;
  items: SharingItemModel[];
  readOnly: boolean;
}
export function SharingInfoItems(props: SharingInfoItemsProps) {
  const [sharingItems, setSharingItemsState] =
    useRecoilState(sharingItemsState);
  return (
    <Container>
      {props.panelTitle && <h4>{props.panelTitle}</h4>}
      <ItemsPanel>
        {props.items.map((item) => (
          <SharingInfoItem
            checked={!!sharingItems[item.id]}
            onChange={(checked) => {
              const newState = {
                ...sharingItems,
                [item.id]: !checked ? item.value : null
              };
              setSharingItemsState(newState);
            }}
            key={item.id}
            item={item}
            readOnly={props.readOnly}
          />
        ))}
      </ItemsPanel>
    </Container>
  );
}
