import { Mail, Phone, User } from '@styled-icons/remix-line';
import styled from 'styled-components';
import { UserProfileKey } from '../types/user-profile-key';

const MailIcon = styled(Mail)``;
const PhoneIcon = styled(Phone)``;
const FullNameIcon = styled(User)``;

export interface UserProfileIconProps {
  iconKey: UserProfileKey;
}
export function UserProfileIcon(props: UserProfileIconProps) {
  switch (props.iconKey) {
    case 'email': {
      return <MailIcon />;
    }
    case 'phoneNumber': {
      return <PhoneIcon />;
    }
    default: {
      return <FullNameIcon />;
    }
  }
}
