import { useContext, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { queuesState } from '../../atoms/queues-state';
import { userProfileState } from '../../atoms/user-profile-state';
import { ActionItem } from '../../components/action-panel/action-item';
import { BasePageTemplate } from '../../components/base-page-template';
import { t } from '../../locales/i18n';
import { sharingItemsSelector } from '../../selectors/sharing-items-selector';
import { colors } from '../../themes/colors';
import { sizes } from '../../themes/sizes';
import { WSMessages } from '../../web-socket/messages';
import { WSContext } from '../../ws-context/ws-context';
import { SharingItemModel } from './components/sharing-info-item';
import { SharingInfoItems } from './components/sharing-info-items';

const Container = styled.section``;

const InfoPanel = styled.section`
  display: grid;
  justify-content: center;
  gap: ${sizes.padding.huge};
  margin: ${sizes.padding.huge};
`;

const InfoTextPanel = styled.span`
  text-align: center;
`;

const UserProfileInfoPanel = styled.section`
  color: ${colors.primary};
  display: grid;
  justify-content: center;
  margin-bottom: ${sizes.padding.huge};
`;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SharingInfoPageProps {}
export function SharingInfoPage(props: SharingInfoPageProps) {
  const { sendWSMessage, socketIsOpen } = useContext(WSContext);
  const { queueId } = useParams();
  useEffect(() => {
    if (queueId && socketIsOpen) {
      sendWSMessage(WSMessages.getQueueDetail(queueId));
    }
  }, [queueId, sendWSMessage, socketIsOpen]);
  const queues = useRecoilValue(queuesState);
  const queue = queues.find((q) => q.id === queueId);
  const userProfile = useRecoilValue(userProfileState);
  const { sharingItems, profileFulfilled } = sharingItemsSelector(userProfile);
  return (
    <BasePageTemplate
      title={
        <div>
          {t('screen.sharingInfo.title')} - {queue?.serviceProviderName}
        </div>
      }
      actionContent={
        <ActionItem
          to={`/queues/${queueId}/remote-checkin`}
          titleKey="buttons.next"
        />
      }
    >
      <Container>
        <InfoPanel>
          <InfoTextPanel>
            {t('screen.sharingInfoScreen.infoText1')}
          </InfoTextPanel>
          <InfoTextPanel>
            {t('screen.sharingInfoScreen.infoText2')}
          </InfoTextPanel>
          {!profileFulfilled && (
            <InfoTextPanel>
              {t('screen.sharingInfoScreen.completeUserProfileInfo')}
            </InfoTextPanel>
          )}
        </InfoPanel>
        {!profileFulfilled && (
          <UserProfileInfoPanel>
            <Link to="/user-profile">
              <h3>{t('screen.sharingInfoScreen.gotoProfile')}</h3>
            </Link>
          </UserProfileInfoPanel>
        )}

        <SharingInfoItems items={sharingItems} readOnly={false} />
      </Container>
    </BasePageTemplate>
  );
}
