import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { queuesState } from '../../atoms/queues-state';
import { ActionItem } from '../../components/action-panel/action-item';
import { BasePageTemplate } from '../../components/base-page-template';
import { colors } from '../../themes/colors';
import { sizes } from '../../themes/sizes';
import { openHoursFormatter } from '../../utils/formatters/business-hours-formatter';
import {
  getQueueInfoMessage,
  queueStatusFormatter
} from '../../utils/formatters/queue-formatter';
import {
  getColorByQueueStatus,
  getQueueCheckInfo
} from '../../utils/queue-utils';
import { WSMessages } from '../../web-socket/messages';
import { WSContext } from '../../ws-context/ws-context';
import { QueueImagePanel } from './components/queue-image-panel';
import { TicketImage } from './components/ticket-image';

const ContentPanel = styled.section`
  display: grid;
  grid-template-rows: 25% 1fr;
  height: 100%;
`;
const InfoPanel = styled.section`
  display: grid;
  font-family: OpenSans;
  grid-template-rows: auto auto 1fr 3fr auto;
  margin: ${sizes.padding.huge};
  gap: ${sizes.padding.big};
  justify-items: center;
`;

const QueueStatusMessage = styled.section<{ color: string }>`
  font-size: ${sizes.fontSizes.big};
  color: ${(props) => props.color};
`;

const QueueInfoMessage = styled.section`
  font-size: ${sizes.fontSizes.big};
`;

const OpenHoursMessage = styled.section<{ color: string }>`
  color: ${(props) => props.color};
`;
const ProviderMessage = styled.section`
  color: ${colors.greyDarker};
`;

export function QueueDetailPage() {
  const { sendWSMessage, socketIsOpen } = useContext(WSContext);
  const { queueId } = useParams();
  useEffect(() => {
    if (queueId && socketIsOpen) {
      sendWSMessage(WSMessages.getQueueDetail(queueId));
    }
  }, [queueId, sendWSMessage, socketIsOpen]);
  const queues = useRecoilValue(queuesState);
  const queue = queues.find((q) => q.id === queueId);
  const statusColor = getColorByQueueStatus(queue?.connectionStatus);
  const queueCheckInfo = getQueueCheckInfo(queue);
  useEffect(() => {
    if (!window?.navigator?.vibrate) {
      return;
    }
    if (queueCheckInfo.isCheckedIn && queueCheckInfo.servedNow) {
      window.navigator.vibrate([200, 100, 200]);
    }
    if (queueCheckInfo.isCheckedIn && !queueCheckInfo.servedNow) {
      window.navigator.vibrate(100);
    }
  }, [queueCheckInfo.isCheckedIn, queueCheckInfo.servedNow]);
  return (
    <BasePageTemplate
      title={
        <div>
          {queue?.name} - {queue?.serviceProviderName}
        </div>
      }
      navigateTo="/"
      actionContent={
        <>
          {!queueCheckInfo.isCheckedIn && queueCheckInfo.isQueueOpen && (
            <>
              <ActionItem
                titleKey="buttons.enterPIN"
                to={`/queues/${queueId}/enter-ticket-pin`}
              />
              {queue?.allowRemoteCheckin && (
                <ActionItem
                  titleKey="buttons.checkIn"
                  to={`/queues/${queueId}/sharing-info`}
                />
              )}
            </>
          )}
          {queueCheckInfo.isCheckedIn && queueCheckInfo.isQueueOpen && (
            <ActionItem
              titleKey="buttons.leaveQueue"
              to={`/queues/${queueId}/leave-queue`}
            />
          )}
        </>
      }
    >
      <ContentPanel>
        <QueueImagePanel queueCheckInfo={queueCheckInfo} />

        <InfoPanel>
          <QueueInfoMessage>
            {getQueueInfoMessage(queue, queueCheckInfo)}
          </QueueInfoMessage>
          <QueueStatusMessage color={statusColor}>
            {queueStatusFormatter(queue?.connectionStatus)}
          </QueueStatusMessage>
          <OpenHoursMessage color={statusColor}>
            {openHoursFormatter(queue?.onlineOpenHours || [])}
          </OpenHoursMessage>
          {queueCheckInfo.isCheckedIn && (
            <TicketImage ticket={queueCheckInfo.myTicket} />
          )}

          <ProviderMessage>{queue?.providerMessage}</ProviderMessage>
        </InfoPanel>
      </ContentPanel>
    </BasePageTemplate>
  );
}
