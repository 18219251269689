import { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { showAppError } from '../components/app-notification';
import { BasePageTemplate } from '../components/base-page-template';
import { NumPad } from '../components/num-pad';
import { t } from '../locales/i18n';
import {
  WSEnterPinResponse,
  WSMessages,
  WS_ENTER_PIN_RESPONSE
} from '../web-socket/messages';
import { WSContext } from '../ws-context/ws-context';

export function EnterTicketPinPage() {
  const navigate = useNavigate();
  const { queueId } = useParams();
  const { sendWSMessage, subscribeMessage, unsubscribeMessage } =
    useContext(WSContext);
  const sendTicketPin = (pin: string) => {
    if (queueId && pin) {
      sendWSMessage(WSMessages.enterPIN(queueId, pin));
    }
  };

  useEffect(() => {
    subscribeMessage(WS_ENTER_PIN_RESPONSE, (msg: WSEnterPinResponse) => {
      console.log(msg);
      if (msg.result === 'error') {
        showAppError(msg.errorCode);
      } else {
        navigate(`/queues/${queueId}`, { replace: true });
      }
    });
    return () => {
      unsubscribeMessage(WS_ENTER_PIN_RESPONSE);
    };
  }, [navigate, queueId, subscribeMessage, unsubscribeMessage]);
  return (
    <BasePageTemplate title={t('screen.registerTicket.title')}>
      <NumPad
        inputLength={5}
        onEnter={(code) => {
          sendTicketPin(code);
        }}
      />
    </BasePageTemplate>
  );
}
