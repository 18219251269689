import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { queuesState } from '../atoms/queues-state';
import { ActionPanel } from '../components/action-panel/action-panel';
import { ActionItem } from '../components/action-panel/action-item';
import { NavBar } from '../components/nav-bar';
import { PageContainer } from '../components/page-container';
import { PageLayout, MainContentLayout } from '../layouts/app-layout';
import { t } from '../locales/i18n';
import { sizes } from '../themes/sizes';
import { QueueListItem } from './components/queue-list-item';
import { useContext, useEffect } from 'react';
import { WSContext } from '../ws-context/ws-context';
import { WSMessages } from '../web-socket/messages';
import { getQueueCheckInfo } from '../utils/queue-utils';

const PageContentPanel = styled.section``;

const QueueListContainer = styled.section`
  margin: ${sizes.padding.big} 0;
  display: grid;
  gap: ${sizes.padding.medium};
`;

export function QueueListPage() {
  const { sendWSMessage, socketIsOpen } = useContext(WSContext);

  const navigate = useNavigate();
  useEffect(() => {
    if (socketIsOpen) {
      sendWSMessage(WSMessages.getMyQueues());
    }
  }, [sendWSMessage, socketIsOpen]);
  const queues = useRecoilValue(queuesState);
  const checkedInQueues = queues.filter(
    (q) => getQueueCheckInfo(q).isCheckedIn
  );
  const notCheckedInQueues = queues.filter(
    (q) => !getQueueCheckInfo(q).isCheckedIn
  );

  return (
    <PageLayout>
      <NavBar />
      <MainContentLayout>
        <PageContainer>
          <PageContentPanel>
            {checkedInQueues.length > 0 && (
              <>
                <h2>{t('titles.checkedQueues')}</h2>
                <QueueListContainer>
                  {checkedInQueues.map((q) => (
                    <QueueListItem key={q.id} queue={q} />
                  ))}
                </QueueListContainer>
                <br />
              </>
            )}
            {notCheckedInQueues.length > 0 && (
              <>
                <h2>{t('titles.myQueues')}</h2>
                <QueueListContainer>
                  {notCheckedInQueues.map((q) => (
                    <QueueListItem key={q.id} queue={q} />
                  ))}
                </QueueListContainer>
                <br />
              </>
            )}
          </PageContentPanel>
          <ActionPanel>
            <ActionItem
              iconName="add"
              titleKey="buttons.addProvider"
              onClick={() => navigate('/enter-provider-code')}
            />
          </ActionPanel>
        </PageContainer>
      </MainContentLayout>
    </PageLayout>
  );
}
