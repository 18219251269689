import { ReactNode } from 'react';
import styled from 'styled-components';
import { sizes } from '../../themes/sizes';

const ActionPanelContainer = styled.section`
  margin-top: ${sizes.padding.big};
  margin-bottom: ${sizes.padding.medium};
  display: grid;
  grid-auto-flow: column;

  gap: ${sizes.padding.big};
`;
export interface ActionPanelProps {
  children: ReactNode;
}

export function ActionPanel(props: ActionPanelProps) {
  return <ActionPanelContainer>{props.children}</ActionPanelContainer>;
}
