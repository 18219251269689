import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`

  @font-face {
    font-family: 'Oswald';
    src: local('Oswald'), url('/assets/fonts/Oswald-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Oswald';
    src: local('Oswald'), url('/assets/fonts/Oswald-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'OpenSans';
    src: local('OpenSans'),  local('Open Sans'), url('/assets/fonts/OpenSans-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
  }

  html, body {
    margin: 0;
    padding: 0;
  }

  body {
    font-family: 'OpenSans';

  }

  #root, body, html {
    height: 100%;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Oswald';
    margin: 0;
    padding: 0;
    font-weight: 400;
  }

  a, a:visited {
    text-decoration: none;
    color: inherit
  }
`;
