import { ReactNode, useEffect, useMemo, useState } from 'react';
import { appConfig } from '../app-config';
import { SocketClient } from '../web-socket/socket-client';
import { WSContext, WSContextWrapper } from '../ws-context/ws-context';

export function WSContextProvider({ children }: { children: ReactNode }) {
  const [isSocketOpen, setSocketIsOpen] = useState(false);
  useEffect(() => {
    const socketClient = SocketClient.getInstance(appConfig.socketUrl);
    if (isSocketOpen) {
      return;
    }
    socketClient.connect(appConfig.socketUrl);
    socketClient.on('open', () => {
      setSocketIsOpen(true);
    });
    socketClient.on('close', () => {
      setSocketIsOpen(false);
    });
    return () => {
      socketClient.removeAllListeners('open');
      socketClient.removeAllListeners('close');
    };
  }, [isSocketOpen]);

  const ctx: WSContextWrapper = useMemo(
    () => ({
      get socketIsOpen() {
        return isSocketOpen;
      },
      sendWSMessage(msg) {
        SocketClient.getInstance()?.sendMessage(msg);
      },

      subscribeMessage(cmd, callback) {
        console.log('subscribeMessage', cmd);
        SocketClient.getInstance().on(cmd, callback);
      },

      unsubscribeMessage(cmd) {
        console.log('unsubscribeMessage', cmd);
        if (SocketClient.getInstance().removeAllListeners) {
          SocketClient.getInstance().removeAllListeners(cmd);
        }
      }
    }),
    [isSocketOpen]
  );

  return <WSContext.Provider value={ctx}>{children}</WSContext.Provider>;
}
