/* eslint-disable @typescript-eslint/no-explicit-any */
import Polyglot, { InterpolationOptions } from 'node-polyglot';
import { cs } from './cs';
import { QueueStatus } from '../types/models/queue-model';
const polyglot = new Polyglot();
const phrases: any = {
  cs
};

export function getLocale() {
  return 'cs';
}

function setLocale() {
  const locale = getLocale();
  polyglot.extend(phrases[locale]);
  polyglot.locale(locale);
}

setLocale();

export function t(scope: string, options: InterpolationOptions = {}) {
  return polyglot.t(scope, options);
}

export function tAuthError(scope: string, options = {}) {
  return polyglot.t(`errors.auth.${scope}`, options);
}

export function tError(scope: string, options = {}) {
  return polyglot.t(`errors.${scope}`, options);
}

export function tScreenTitle(key: string, options = {}) {
  return polyglot.t(`screen.${key}.title`, options);
}

export function tQueueStatus(queueStatus: QueueStatus) {
  return polyglot.t(`statuses.queueStatus.${queueStatus}`);
}
