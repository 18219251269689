/* eslint-disable @typescript-eslint/no-explicit-any */
import { To, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { ArrowLeftS } from '@styled-icons/remix-line';
import { colors } from '../themes/colors';
import { sizes } from '../themes/sizes';
import { ReactNode, ReactNodeArray } from 'react';

const TopBarContainer = styled.section`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: ${sizes.padding.medium};
  padding: ${sizes.padding.big};
  padding-bottom: 0;
`;
const NavHomeIcon = styled(ArrowLeftS)`
  width: ${sizes.icon.medium};
  height: ${sizes.icon.medium};
  fill: ${colors.primary};
`;

const Title = styled.h1`
  font-size: ${sizes.fontSizes.big};
  font-weight: 400;
`;

export interface TopBarProps {
  children: ReactNode | ReactNodeArray;
  navigateTo?: To;
}

export function TopBar(props: TopBarProps) {
  const navigate = useNavigate();
  const navTo = props.navigateTo || -1;
  return (
    <TopBarContainer>
      <span onClick={() => navigate(navTo as any)}>
        <NavHomeIcon />
      </span>
      <Title>{props.children}</Title>
    </TopBarContainer>
  );
}
