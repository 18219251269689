import styled from 'styled-components';
import { colors } from '../../../themes/colors';
import { sizes } from '../../../themes/sizes';
import { TicketModel } from '../../../types/models/ticket-model';

const Container = styled.div`
  width: 200px;
  display: grid;
  align-items: center;
  justify-items: center;

  gap: ${sizes.padding.medium};

  border-radius: 2px;
  background-color: #f2f2f2;
  font-family: 'Oswald';
  color: ${colors.greyDarker};
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
`;

const TicketNumber = styled.div`
  margin-top: ${sizes.padding.medium};
  font-size: 4rem;
`;

const TicketPIN = styled.div`
  margin-bottom: ${sizes.padding.medium};
  font-size: ${sizes.fontSizes.big};
`;

export interface TicketImageProps {
  ticket: TicketModel | null | undefined;
}
export function TicketImage(props: TicketImageProps) {
  const { ticket } = props;
  if (!ticket) {
    return null;
  }
  return (
    <Container>
      <TicketNumber>{ticket.formattedNumber}</TicketNumber>
      <TicketPIN>PIN: {ticket.pin}</TicketPIN>
    </Container>
  );
}
