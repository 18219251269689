import {
  QueueCheckInfoModel,
  QueueModel,
  QueueStatus
} from '../../types/models/queue-model';
import { t } from '../../locales/i18n';

export function queueStatusFormatter(
  queueStatus: QueueStatus | null | undefined
) {
  switch (queueStatus) {
    case 'open': {
      return t('statuses.queueStatus.open');
    }
    case 'closed': {
      return t('statuses.queueStatus.closed');
    }
    default: {
      return t('statuses.queueStatus.disconnected');
    }
  }
}

function getQueueInfoMessageCheckedIn(
  queue: QueueModel,
  queueCheckInfo: QueueCheckInfoModel
) {
  if (queueCheckInfo.overdue) {
    return t('statuses.queueInfo.overdue');
  }
  if (queueCheckInfo.servedNow) {
    return t('statuses.queueInfo.servedNow');
  }
  if (queueCheckInfo.queueTicketIndex === 0) {
    return t('statuses.queueInfo.peopleInFrontOfMe.zero');
  }
  return t('statuses.queueInfo.peopleInFrontOfMe.smartCount', {
    smart_count: queueCheckInfo.queueTicketIndex
  });
}

function getQueueInfoMessageNotCheckedIn(queue: QueueModel) {
  if (queue.tickets.length === 0) {
    return t('statuses.queueInfo.peopleInQueue.zero');
  }
  return t('statuses.queueInfo.peopleInQueue.smartCount', {
    smart_count: queue.tickets.length
  });
}

export function getQueueInfoMessage(
  queue: QueueModel | null | undefined,
  queueCheckInfo: QueueCheckInfoModel
) {
  if (!queue) {
    return '';
  }
  if (queueCheckInfo.isCheckedIn) {
    return getQueueInfoMessageCheckedIn(queue, queueCheckInfo);
  }
  return getQueueInfoMessageNotCheckedIn(queue);
}
