import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';

import { signOut } from 'supertokens-auth-react/recipe/thirdpartypasswordless';
import { userProfileState } from '../atoms/user-profile-state';
import { ActionItem } from '../components/action-panel/action-item';
import { showAppError, showAppSuccess } from '../components/app-notification';
import { BasePageTemplate } from '../components/base-page-template';
import { t } from '../locales/i18n';
import {
  WSMessages,
  WSSaveCustomerProfile,
  WSSaveCustomerProfileResponse,
  WS_SAVE_CUSTOMER_PROFILE_RESPONSE
} from '../web-socket/messages';
import { WSContext } from '../ws-context/ws-context';
import { InputGroup } from './queue-detail/components/inputs/input-group';
import { TextInput } from './queue-detail/components/inputs/text-input';

export const Container = styled.section`
  // Your style here
`;

export function UserProfilePage() {
  const session = useSessionContext();
  const navigate = useNavigate();
  const { sendWSMessage, socketIsOpen, subscribeMessage, unsubscribeMessage } =
    useContext(WSContext);
  const [userProfile, setUserProfile] = useRecoilState(userProfileState);

  useEffect(() => {
    subscribeMessage(
      WS_SAVE_CUSTOMER_PROFILE_RESPONSE,
      (msg: WSSaveCustomerProfileResponse) => {
        console.log(msg);
        if (msg.result === 'error') {
          showAppError(msg.errorCode);
        } else {
          showAppSuccess('userProfileUpdated');
          navigate('/', { replace: true });
        }
      }
    );
    return () => {
      unsubscribeMessage(WS_SAVE_CUSTOMER_PROFILE_RESPONSE);
    };
  }, [navigate, subscribeMessage, unsubscribeMessage]);

  if (!userProfile || session.loading) {
    return null;
  }
  const onLogout = async () => {
    await signOut();
    navigate('/auth');
  };

  const updateProfileField = (
    key: 'fullName' | 'phoneNumber',
    value: string
  ) => {
    const newUserProfile = { ...userProfile };
    newUserProfile[key] = value;
    setUserProfile(newUserProfile);
  };

  const handleSaveProfile = () => {
    if (socketIsOpen) {
      sendWSMessage(WSMessages.saveCustomerProfile(userProfile));
    }
  };

  return (
    <BasePageTemplate
      title={t('screen.userProfile.title')}
      actionContent={
        <>
          <ActionItem
            iconName="save"
            titleKey="buttons.signOut"
            onClick={onLogout}
          />
          <ActionItem
            iconName="save"
            primary
            titleKey="buttons.saveProfile"
            onClick={handleSaveProfile}
          />
        </>
      }
    >
      <InputGroup>
        <TextInput
          label={t('components.userProfile.email')}
          value={userProfile.email || ''}
          disabled
        />
        <TextInput
          label={t('components.userProfile.fullName')}
          value={userProfile.fullName || ''}
          onChange={(val) => updateProfileField('fullName', val)}
        />
        <TextInput
          label={t('components.userProfile.phoneNumber')}
          value={userProfile.phoneNumber || ''}
          onChange={(val) => updateProfileField('phoneNumber', val)}
        />
      </InputGroup>
    </BasePageTemplate>
  );
}
