import { find, findIndex } from 'rambda';
import { colors } from '../themes/colors';
import {
  QueueCheckInfoModel,
  QueueModel,
  QueueStatus
} from '../types/models/queue-model';

const defaultCheckInfo: QueueCheckInfoModel = {
  ticketCount: 0,
  isQueueOpen: false,
  isQueueConnected: false,
  isCheckedIn: false,
  overdue: false,
  servedNow: false,
  queueTicketIndex: -1,
  myTicket: null
};

export function getQueueCheckInfo(queue: QueueModel | null | undefined) {
  if (!queue) {
    return defaultCheckInfo;
  }
  const queueTicketIndex = findIndex((t) => t.isCheckedIn, queue.tickets);
  if (queueTicketIndex !== -1) {
    return {
      ticketCount: queue.tickets?.length || 0,
      isQueueOpen: queue.connectionStatus === 'open',
      isQueueConnected: queue.connectionStatus !== 'disconnected',
      isCheckedIn: true,
      overdue: false,
      servedNow: false,
      queueTicketIndex,
      myTicket: queue.tickets[queueTicketIndex]
    };
  }
  const servedNowTicket = find((t) => t.isCheckedIn, queue.allCurrentTickets);
  if (servedNowTicket) {
    return {
      ticketCount: queue.tickets?.length || 0,
      isQueueOpen: queue.connectionStatus === 'open',
      isQueueConnected: queue.connectionStatus !== 'disconnected',
      isCheckedIn: true,
      overdue: false,
      servedNow: true,
      queueTicketIndex: -1,
      myTicket: servedNowTicket
    };
  }
  const overdueTicket = find((t) => t.isCheckedIn, queue.allOverdueTickets);
  if (overdueTicket) {
    return {
      ticketCount: queue.tickets?.length || 0,
      isQueueOpen: queue.connectionStatus === 'open',
      isQueueConnected: queue.connectionStatus !== 'disconnected',
      isCheckedIn: true,
      overdue: true,
      servedNow: false,
      queueTicketIndex: -1,
      myTicket: overdueTicket
    };
  }
  return {
    ...defaultCheckInfo,
    ticketCount: queue.tickets?.length || 0,
    isQueueOpen: queue.connectionStatus === 'open',
    isQueueConnected: queue.connectionStatus !== 'disconnected'
  };
}

export function getColorByQueueStatus(
  queueStatus: QueueStatus | null | undefined
) {
  switch (queueStatus) {
    case 'open': {
      return colors.primary;
    }
    case 'disconnected': {
      return colors.error;
    }
    default: {
      return colors.greyDarkest;
    }
  }
}
