import { SharingItemModel } from '../pages/queue-detail/components/sharing-info-item';
import { CustomerModel } from '../types/models/customer-model';

export function sharingItemsSelector(userProfile: CustomerModel | null) {
  const sharingItems: SharingItemModel[] = [];
  if (userProfile?.email) {
    sharingItems.push({ id: 'email', value: userProfile.email });
  }
  if (userProfile?.fullName) {
    sharingItems.push({ id: 'fullName', value: userProfile.fullName });
  }
  if (userProfile?.phoneNumber) {
    sharingItems.push({ id: 'phoneNumber', value: userProfile.phoneNumber });
  }

  const profileFulfilled =
    userProfile?.email && userProfile?.phoneNumber && userProfile?.fullName;
  return {
    sharingItems,
    profileFulfilled
  };
}
