import SuperTokens from 'supertokens-auth-react';
import ThirdPartyPasswordless from 'supertokens-auth-react/recipe/thirdpartypasswordless';
import Session from 'supertokens-auth-react/recipe/session';
import { appConfig } from './app-config';
import { SocketClient } from './web-socket/socket-client';
import { SuperTokensConfig } from 'supertokens-auth-react/lib/build/types';

export function initSupertokens() {
  const scCfg: SuperTokensConfig = {
    languageTranslations: {
      defaultLanguage: 'cs',
      translations: {
        cs: {
          EMAIL_VERIFICATION_RESEND_SUCCESS: 'Email odeslán',
          EMAIL_VERIFICATION_SEND_TITLE: 'Zkontrolujte svůj email',
          EMAIL_VERIFICATION_SEND_DESC_START: '',
          EMAIL_VERIFICATION_SEND_DESC_STRONG: 'Klikněte na odkaz',
          EMAIL_VERIFICATION_SEND_DESC_END:
            ' v emailu, který jsme vám právě poslali, abyste dokončili registraci.',
          EMAIL_VERIFICATION_RESEND_BTN: 'Znovu odeslat email',
          EMAIL_VERIFICATION_LOGOUT: 'Odhlásit se',
          EMAIL_VERIFICATION_SUCCESS: 'Ověření emailu bylo úspěšné',
          EMAIL_VERIFICATION_CONTINUE_BTN: 'Pokračovat',
          EMAIL_VERIFICATION_CONTINUE_LINK: 'Pokračovat',
          EMAIL_VERIFICATION_EXPIRED: 'Ověření emailu vypršelo',
          EMAIL_VERIFICATION_ERROR_TITLE: 'Ověření emailu selhalo',
          EMAIL_VERIFICATION_ERROR_DESC:
            'Ověření emailu selhalo. Zkuste to prosím znovu.',
          EMAIL_VERIFICATION_LINK_CLICKED_HEADER: 'Ověření emailu',
          EMAIL_VERIFICATION_LINK_CLICKED_DESC:
            'Kliknutím na tlačítko níže dokončíte ověření emailu.',
          EMAIL_VERIFICATION_LINK_CLICKED_CONTINUE_BUTTON: 'Pokračovat',

          GENERAL_ERROR_EMAIL_UNDEFINED: 'Prosím vyplňte svůj email',
          GENERAL_ERROR_EMAIL_NON_STRING: 'Email není validní',
          GENERAL_ERROR_EMAIL_INVALID: 'Email není validní',

          GENERAL_ERROR_PHONE_UNDEFINED: 'Prosím vyplňte své telefonní číslo',
          GENERAL_ERROR_PHONE_NON_STRING: 'Telefonní číslo není validní',
          GENERAL_ERROR_PHONE_INVALID: 'Telefonní číslo není validní',

          GENERAL_ERROR_EMAIL_OR_PHONE_UNDEFINED:
            'Prosím vyplňte svůj email nebo telefonní číslo',
          GENERAL_ERROR_EMAIL_OR_PHONE_NON_STRING:
            'Email nebo telefonní číslo není validní',
          GENERAL_ERROR_EMAIL_OR_PHONE_INVALID:
            'Email nebo telefonní číslo není validní',

          GENERAL_ERROR_OTP_UNDEFINED: 'Prosím vyplňte OTP',
          GENERAL_ERROR_OTP_INVALID: 'Neplatné OTP',
          GENERAL_ERROR_OTP_EXPIRED: 'OTP vypršelo',
          GENERAL_ERROR_OTP_NON_STRING: 'OTP není validní',
          GENERAL_ERROR_OTP_EMPTY: 'OTP není validní',

          ERROR_SIGN_IN_UP_LINK: 'Neplatný odkaz',
          ERROR_SIGN_IN_UP_RESEND_RESTART_FLOW:
            'Přihlášení vypršelo. Zkuste to prosím znovu.',
          ERROR_SIGN_IN_UP_CODE_CONSUME_RESTART_FLOW:
            'Přihlášení vypršelo. Zkuste to prosím znovu.',

          PWLESS_CLOSE_TAB_TITLE: 'Přihlášení proběhlo úspěšně',
          PWLESS_CLOSE_TAB_SUBTITLE_LINE1: 'Byl jste úspěšně přihlášen.',
          PWLESS_CLOSE_TAB_SUBTITLE_LINE2: 'Prosím zavřete tuto záložku.',

          PWLESS_SIGN_IN_UP_HEADER_TITLE: 'Registrace / přihlášení',

          PWLESS_SIGN_IN_UP_FOOTER_START: 'Pokračováním souhlasíte s ',
          PWLESS_SIGN_IN_UP_FOOTER_TOS: 'Podmínkami služby',
          PWLESS_SIGN_IN_UP_FOOTER_AND: ' a ',
          PWLESS_SIGN_IN_UP_FOOTER_PP: 'Zásadami ochrany osobních údajů',
          PWLESS_SIGN_IN_UP_FOOTER_END: '',

          PWLESS_SIGN_IN_UP_PHONE_LABEL: 'Phone Number',
          PWLESS_SIGN_IN_UP_EMAIL_OR_PHONE_LABEL: 'Email or Phone number',
          PWLESS_EMAIL_OR_PHONE_INVALID_INPUT_GUESS_PHONE_ERR:
            'Please enter a valid phone number with its country code.',

          PWLESS_LINK_SENT_RESEND_SUCCESS: 'Odkaz byl úspěšně odeslán',
          PWLESS_LINK_SENT_RESEND_TITLE: 'Odkaz byl úspěšně odeslán',
          PWLESS_LINK_SENT_RESEND_DESC_START_EMAIL: 'Zaslali jsme odkaz na ',
          PWLESS_LINK_SENT_RESEND_DESC_START_PHONE:
            'Zaslali jsme SMS s odkazem na ',
          PWLESS_LINK_SENT_RESEND_DESC_END_EMAIL:
            ' . Prosím klikněte na odkaz v emailu pro dokončení registrace.',
          PWLESS_LINK_SENT_RESEND_DESC_END_PHONE: '',

          PWLESS_SIGN_IN_UP_CHANGE_CONTACT_INFO_EMAIL: 'Změnit email',
          PWLESS_SIGN_IN_UP_CHANGE_CONTACT_INFO_PHONE: 'Změnit telefonní číslo',

          PWLESS_LINK_CLICKED_CONTINUE_HEADER: 'Registrace / přihlášení',
          PWLESS_LINK_CLICKED_CONTINUE_DESC:
            'Prosím klikněte na tlačítko níže pro dokončení registrace.',
          PWLESS_LINK_CLICKED_CONTINUE_BUTTON: 'Pokračovat',

          PWLESS_RESEND_SUCCESS_EMAIL: 'Email byl úspěšně odeslán',
          PWLESS_RESEND_SUCCESS_PHONE: 'SMS byla úspěšně odeslána',

          PWLESS_RESEND_BTN_DISABLED_START: 'Odesláno ',
          PWLESS_RESEND_BTN_DISABLED_END: '',
          PWLESS_RESEND_BTN_EMAIL: 'Znovu odeslat email',
          PWLESS_RESEND_BTN_PHONE: 'Znovu odeslat SMS',

          PWLESS_USER_INPUT_CODE_HEADER_TITLE: 'Vložte OTP',
          PWLESS_USER_INPUT_CODE_HEADER_SUBTITLE: 'OTP bylo odesláno na',
          PWLESS_USER_INPUT_CODE_HEADER_SUBTITLE_LINK: 'OTP bylo odesláno na',
          PWLESS_USER_INPUT_CODE_INPUT_LABEL: 'OTP',

          BRANDING_POWERED_BY_START: ' ',
          BRANDING_POWERED_BY_END: '',
          SOMETHING_WENT_WRONG_ERROR: 'Něco se pokazilo',
          THIRD_PARTY_PASSWORDLESS_SIGN_IN_AND_UP_HEADER_TITLE:
            'Zaregistrovat se / přihlásit se',
          THIRD_PARTY_PASSWORDLESS_SIGN_IN_AND_UP_DIVIDER_OR: 'nebo',
          PWLESS_SIGN_IN_UP_EMAIL_LABEL: 'Email',
          PWLESS_SIGN_IN_UP_CONTINUE_BUTTON: 'Pokračovat',

          THIRD_PARTY_SIGN_IN_AND_UP_HEADER_TITLE: 'Registrace / přihlášení',

          THIRD_PARTY_SIGN_IN_UP_FOOTER_START: 'pokračováním souhlasíte s ',
          THIRD_PARTY_SIGN_IN_UP_FOOTER_TOS: 'Podmínkami služby',
          THIRD_PARTY_SIGN_IN_UP_FOOTER_AND: ' a ',
          THIRD_PARTY_SIGN_IN_UP_FOOTER_PP: 'Zásadami ochrany osobních údajů',
          THIRD_PARTY_SIGN_IN_UP_FOOTER_END: '',
          THIRD_PARTY_PROVIDER_DEFAULT_BTN_START: 'Pokračovat s ',
          THIRD_PARTY_PROVIDER_DEFAULT_BTN_END: '',

          THIRD_PARTY_ERROR_NO_EMAIL:
            'nemáme email, který bychom mohli použít pro registraci'
        }
      }
    },
    appInfo: {
      appName: 'nextinq-mobile',
      apiDomain: appConfig.apiDomain,
      websiteDomain: appConfig.websiteDomain,
      apiBasePath: '/public-api/end-users/v2',
      websiteBasePath: '/auth'
    },
    recipeList: [
      ThirdPartyPasswordless.init({
        contactMethod: 'EMAIL',
        onHandleEvent: async (context) => {
          if (context.action === 'SUCCESS') {
            SocketClient.getInstance().reconnect();
          }
        },
        signInUpFeature: {
          providers: [
            ThirdPartyPasswordless.Google.init()
          ]
        }
      }),
      Session.init()
    ]
  };
  SuperTokens.init(scCfg);
}
