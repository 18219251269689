import { ReactNode, ReactNodeArray } from 'react';
import { PageLayout, MainContentLayout } from '../layouts/app-layout';
import { ActionPanel } from './action-panel/action-panel';
import { PageContainer } from './page-container';
import { TopBar } from './top-bar';
import styled from 'styled-components';
import { To } from 'react-router-dom';

const ContentContainer = styled.section``;

export interface BasePageTemplateProps {
  title: string | ReactNode;
  children: ReactNode | ReactNodeArray;
  actionContent?: ReactNode | ReactNodeArray;
  navigateTo?: To;
}
export function BasePageTemplate(props: BasePageTemplateProps) {
  const { children, title, actionContent, navigateTo } = props;
  return (
    <PageLayout>
      <TopBar navigateTo={navigateTo}>{title}</TopBar>
      <MainContentLayout>
        <PageContainer>
          <ContentContainer>{children}</ContentContainer>
          <ActionPanel>{actionContent}</ActionPanel>
        </PageContainer>
      </MainContentLayout>
    </PageLayout>
  );
}
