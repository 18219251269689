import { MouseEvent } from 'react';
import styled, { css } from 'styled-components';
import { colors } from '../../themes/colors';
import { sizes } from '../../themes/sizes';
import { QueueModel } from '../../types/models/queue-model';
import { ArrowRightS, Navigation } from '@styled-icons/remix-line';
import { gpsNavLink } from '../../utils/gps-nav-link';
import { QueueItemStatus } from './queue-item-status';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { openHoursFormatter } from '../../utils/formatters/business-hours-formatter';
import { getQueueInfoMessage } from '../../utils/formatters/queue-formatter';
import { getQueueCheckInfo } from '../../utils/queue-utils';

export interface QueueListItemProps {
  queue: QueueModel;
}

const ItemContainer = styled.section<{ isCheckedIn: boolean }>`
  border: ${sizes.borderWidth.thin} solid ${colors.greyLightest};
  padding: ${sizes.padding.medium};
  border-radius: ${sizes.borderRadius.big};
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr auto;
  align-items: center;
  ${(props) =>
    props.isCheckedIn &&
    css`
      background-color: ${colors.primaryLightest};
    `}
`;

const CheckinInfo = styled.section<{ isCheckedIn: boolean }>`
  font-size: ${sizes.fontSizes.big};
  margin-top: ${sizes.padding.small};
  ${(props) =>
    props.isCheckedIn &&
    css`
      color: ${colors.secondary};
    `}
`;

const HeaderSection = styled.section`
  h3 {
    font-size: ${sizes.fontSizes.big};
    font-family: 'OpenSans';
    color: ${colors.greyDarker};
  }
`;
const DetailSection = styled.section`
  font-size: ${sizes.fontSizes.small};
  color: ${colors.grey};
`;

const ActionSection = styled.section`
  min-height: 45px;
  grid-auto-flow: column;
  align-self: center;
  display: grid;
  align-items: center;
  justify-content: space-between;
  gap: ${sizes.padding.medium};
`;
const ItemContentSection = styled.section`
  display: grid;
  grid-template-rows: auto auto 1fr;
`;
const ItemNavSection = styled.section``;

const NavDetailIcon = styled(ArrowRightS)`
  width: ${sizes.icon.medium};
  height: ${sizes.icon.medium};
  fill: ${colors.primary};
`;

const NavigationIcon = styled(Navigation)`
  width: ${sizes.icon.tiny};
  height: ${sizes.icon.tiny};
  fill: ${colors.grey};
`;

export function QueueListItem(props: QueueListItemProps) {
  const { queue } = props;
  const navigate = useNavigate();

  const navToQueueDetail = useCallback(() => {
    navigate(`/queues/${queue.id}`);
  }, [navigate, queue.id]);

  const navToGpsLocation = useCallback(
    (e: MouseEvent<SVGSVGElement>) => {
      e.stopPropagation();
      window.location.assign(gpsNavLink(queue.gps.coordinates));
    },
    [queue.gps.coordinates]
  );
  const queueCheckInfo = getQueueCheckInfo(queue);
  return (
    <ItemContainer
      isCheckedIn={queueCheckInfo.isCheckedIn}
      onClick={navToQueueDetail}
    >
      <ItemContentSection>
        <HeaderSection>
          <h3>{queue.title}</h3>
        </HeaderSection>
        <DetailSection>
          <address>{queue.locationShortAddress}</address>
          <CheckinInfo isCheckedIn={queueCheckInfo.isCheckedIn}>
            {getQueueInfoMessage(queue, queueCheckInfo)}
          </CheckinInfo>
        </DetailSection>
        <ActionSection>
          <QueueItemStatus
            status={queue.connectionStatus}
            titleSuffix={openHoursFormatter(queue?.onlineOpenHours || []).join(
              ' '
            )}
          />
          <NavigationIcon onClick={navToGpsLocation} />
        </ActionSection>
      </ItemContentSection>
      <ItemNavSection>
        <NavDetailIcon />
      </ItemNavSection>
    </ItemContainer>
  );
}
