import { toast } from 'react-hot-toast';
import { t } from '../locales/i18n';
import { ErrorWarning, ShieldCheck } from '@styled-icons/remix-line';

export function showAppError(errorKey: string | null | undefined) {
  toast(t(`errors.${errorKey}`, { _: t('errors.unknownError') }), {
    icon: <ErrorWarning size={48} />
  });
}

export function showAppSuccess(key: string | null | undefined) {
  toast(t(`successes.${key}`), {
    icon: <ShieldCheck size={48} />
  });
}
