export type LogSeverity = 'debug' | 'info' | 'warn' | 'error';

function log(severity: LogSeverity, message: string, args = {}) {
  const msg = `${severity} - ${message}`;
  if (typeof args === 'string' || args instanceof String) {
    console.log(severity, message, { data: args, message });
  } else {
    const data = { ...args, message: msg };
    console.log(severity, message, data);
  }

  // fetch('https://webhook.logentries.com/noformat/logs/a61e6917-733d-406d-a2f4-f7464d570e2d', {
  //   method: 'POST',
  //   body: JSON.stringify(data)
  // }).catch(e => console.log('Cannot send log entry', data));
}

export function debug(message: string, args = {}) {
  log('debug', message, args);
}

export function info(message: string, args = {}) {
  log('info', message, args);
}

export function warn(message: string, args = {}) {
  log('warn', message, args);
}

export function error(message: string, args = {}) {
  log('error', message, args);
}

export function logException(ex: {}, context = {}) {
  error('exception log', {
    exception: ex,
    context
  });
  // initRaven();
  // context.state = getState();
  //
  // Raven.captureException(ex, {
  //   extra: context
  // });
  /*eslint no-console:0*/
  window.console && console.error && console.error('logException', ex, context);
}
