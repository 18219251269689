import queueEmptyImg from '../../../assets/images/queue/queue-empty.png';
import queueNotEmptyImg from '../../../assets/images/queue/queue-not-empty.png';
import queueCheckinEnter from '../../../assets/images/queue/queue-checkin-enter.png';
import queueCheckinReady from '../../../assets/images/queue/queue-checkin-ready.png';
import queueCheckinOverdue from '../../../assets/images/queue/queue-checkin-overdue.png';
import styled from 'styled-components';
import { QueueCheckInfoModel } from '../../../types/models/queue-model';

const ImagePanel = styled.section<{ imageUrl: string }>`
  width: 100%;
  height: 100%;
  background-image: url('${(p) => p.imageUrl}');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface QueueImagePanelProps {
  queueCheckInfo: QueueCheckInfoModel;
}

function resolveImage(queueCheckInfo: QueueCheckInfoModel) {
  if (!queueCheckInfo.isQueueConnected) {
    return queueEmptyImg;
  }
  if (queueCheckInfo.isCheckedIn) {
    if (queueCheckInfo.overdue) {
      return queueCheckinOverdue;
    }
    if (queueCheckInfo.servedNow) {
      return queueCheckinEnter;
    }
    if (queueCheckInfo.queueTicketIndex === 0) {
      return queueCheckinReady;
    }
  }
  return queueCheckInfo.ticketCount > 0 ? queueNotEmptyImg : queueEmptyImg;
}

export function QueueImagePanel(props: QueueImagePanelProps) {
  return <ImagePanel imageUrl={resolveImage(props.queueCheckInfo)} />;
}
