import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { showAppError, showAppSuccess } from '../components/app-notification';
import { BasePageTemplate } from '../components/base-page-template';
import { NumPad } from '../components/num-pad';
import { t } from '../locales/i18n';
import {
  WSEnterProviderCodeResponse,
  WSMessages,
  WSSaveCustomerProfileResponse,
  WS_ENTER_PROVIDER_CODE_RESPONSE,
  WS_SAVE_CUSTOMER_PROFILE_RESPONSE
} from '../web-socket/messages';
import { WSContext } from '../ws-context/ws-context';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface EnterProviderCodePanelProps {}

export function EnterProviderCodePage(props: EnterProviderCodePanelProps) {
  const navigate = useNavigate();
  const { sendWSMessage, subscribeMessage, unsubscribeMessage } =
    useContext(WSContext);
  const sendProviderCode = (code: string) => {
    sendWSMessage(WSMessages.enterProviderCode(code));
  };

  useEffect(() => {
    subscribeMessage(
      WS_ENTER_PROVIDER_CODE_RESPONSE,
      (msg: WSEnterProviderCodeResponse) => {
        console.log(msg);
        if (msg.result === 'error') {
          showAppError(msg.errorCode);
        } else {
          showAppSuccess('providerCodeEntered');
          navigate('/', { replace: true });
        }
      }
    );
    return () => {
      unsubscribeMessage(WS_ENTER_PROVIDER_CODE_RESPONSE);
    };
  }, [navigate, subscribeMessage, unsubscribeMessage]);
  return (
    <BasePageTemplate title={t('screen.registerQueue.title')}>
      <NumPad
        inputLength={6}
        onEnter={(code) => {
          sendProviderCode(code);
        }}
      />
    </BasePageTemplate>
  );
}
