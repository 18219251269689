import { darken, lighten } from 'polished';

const primary = '#7CC25B';
const secondary = '#E3865A';

export const colors = {
  primary,
  primaryDarker: darken(0.2, primary),
  primaryDarkest: darken(0.4, primary),
  primaryLighter: lighten(0.2, primary),
  primaryLightest: lighten(0.4, primary),
  secondary,
  greyDarkest: '#393e42',
  greyDarker: '#43484d',
  grey: '#5e6977',
  greyLighter: '#86939e',
  greyLightest: '#bdc6cf',
  greyWhite: '#e1e8ee',
  greyOutline: '#bbb',
  searchBg: '#303337',
  success: '#52c41a',
  error: '#ff190c',
  warning: '#faad14',
  disabled: 'hsl(208, 8%, 90%)',
  black: '#000',
  white: '#FFF'
};
