export interface TextInputProps {
  value: string;
  label: string;
  onChange?: (textValue: string) => void;
  disabled?: boolean;
}
export function TextInput(props: TextInputProps) {
  return (
    <label>
      <span>{props.label}</span>
      <input
        type="text"
        disabled={props.disabled}
        value={props.value}
        onChange={(e) => props.onChange && props.onChange(e.target.value)}
      />
    </label>
  );
}
