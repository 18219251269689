import styled from 'styled-components';
import { UserProfileIcon } from '../../../components/user-profile-icon';
import { sizes } from '../../../themes/sizes';
import { UserProfileKey } from '../../../types/user-profile-key';

const Container = styled.section`
  display: grid;
  grid-template-columns: 24px 1fr;
  gap: ${sizes.padding.medium};
  align-items: center;
  label {
    display: grid;
    grid-template-columns: 1fr 32px;

    gap: ${sizes.padding.medium};
    align-items: center;
    input {
      height: 20px;
    }
  }
`;

export interface SharingItemModel {
  id: UserProfileKey;
  value: string;
}
export interface SharingInfoItemProps {
  readOnly: boolean;
  item: SharingItemModel;
  checked: boolean;
  onChange: (checked: boolean) => void;
}

export function SharingInfoItem(props: SharingInfoItemProps) {
  const { readOnly, item, checked, onChange } = props;
  if (readOnly && !checked) {
    return null;
  }
  if (readOnly) {
    return (
      <Container>
        <UserProfileIcon iconKey={item.id} />
        <label>{item.value}</label>
      </Container>
    );
  }

  return (
    <Container>
      <UserProfileIcon iconKey={item.id} />
      <label>
        {item.value}
        <input
          type="checkbox"
          checked={checked}
          onChange={(e) => onChange && onChange(!e.target.checked)}
        />
      </label>
    </Container>
  );
}
