/* eslint-disable @typescript-eslint/no-explicit-any */
import { parseMessage } from './utils/parse-message';
import { debug } from '../utils/logger';
import { WSMessage } from './messages';
import EventEmitter from 'events';
import { T } from 'ramda';

const pingInterval = 5000;
const reconnectBaseIntervalMS = 1000;

const RPC_TIMEOUT = 10000;
const MAX_RECONNECT_COUNT = 3;

export class SocketClient extends EventEmitter {
  private ws: WebSocket | null;
  private url: string;
  private errorCount: number;
  private connectionStartTime: Date;
  private reconnectInterval: NodeJS.Timer | null;
  private pingClearInterval: NodeJS.Timer | null;
  private connectionTimeoutInterval: NodeJS.Timer | null;
  private readonly connectionTimeoutSecs: number;

  private static _instance: SocketClient | null = null;
  static getInstance(url: string | null = null) {
    if (!this._instance) {
      this._instance = new SocketClient();
      if (url) {
        this._instance.connect(url);
      }
    }
    return this._instance;
  }

  constructor() {
    super();
    this.url = '';
    this.ws = null;
    this.errorCount = 0;
    this.connectionStartTime = new Date();
    this.pingClearInterval = null;

    this.connectionTimeoutInterval = null;
    this.connectionTimeoutSecs = 4;
    this.reconnectInterval = null;
  }

  isOpen() {
    return this.ws?.readyState === WebSocket.OPEN;
  }

  clearConnectionTimeout = () => {
    if (this.connectionTimeoutInterval) {
      clearTimeout(this.connectionTimeoutInterval);
      this.connectionTimeoutInterval = null;
    }
  };

  clearPingInterval = () => {
    if (this.pingClearInterval) {
      clearTimeout(this.pingClearInterval);
      this.pingClearInterval = null;
    }
  };

  clearReconnectInterval = () => {
    if (this.reconnectInterval) {
      clearTimeout(this.reconnectInterval);
      this.reconnectInterval = null;
    }
  };

  private handleClose = (event: Event) => {
    this.clearConnectionTimeout();
    debug(`qcloud-socket:onclose:`, {
      reconnectBaseIntervalMS
    });
  };

  private handleError = (event: any) => {
    this.clearConnectionTimeout();
    debug(`qcloud-socket:onerror:'${this.url}', err: ${event.message}`);
    this.errorCount += 1;
  };

  private handleOpen = (event: Event) => {
    debug(`qcloud-socket:onopen:'${this.url}'`);
    this.clearConnectionTimeout();
    this.clearPingInterval();
    if (!this.pingClearInterval) {
      this.pingClearInterval = setInterval(() => {
        const message = { cmd: 'ping' };
        //this.send(null, message);
      }, pingInterval);
    }
  };

  private handleMessage = (event: any) => {
    if (this.reconnectInterval) {
      clearInterval(this.reconnectInterval);
    }
    this.errorCount = 0;
  };

  connect = (url: string) => {
    if (this.ws) {
      return;
    }
    if (this.isOpen()) {
      return;
    }
    this.url = url;
    this.ws = new WebSocket(url);
    debug(`qcloud-socket:starting:'${url}'`);
    this.connectionTimeoutInterval = setTimeout(() => {
      debug(`qcloud-socket:timeout:'${url}'`);
      this.handleBrokenConnection();
    }, this.connectionTimeoutSecs * 1000);
    this.ws.addEventListener('close', (event: Event) => {
      this.emit('close', event);
      this.handleClose(event);
      this.handleBrokenConnection();
    });
    this.ws.addEventListener('error', (event: Event) => {
      this.emit('close', event);
      this.handleError(event);
    });
    this.ws.addEventListener('open', (event: Event) => {
      this.handleOpen(event);
      this.emit('open', event);
    });
    this.ws.addEventListener('message', (messageEvent: MessageEvent<any>) => {
      const { data } = messageEvent;
      if (data) {
        const msg = JSON.parse(data);
        console.log('onmessage', msg);
        this.emit(msg.cmd, msg);
      }
      this.handleMessage(messageEvent);
    });
  };

  reconnect() {
    this.disconnect();
    this.connect(this.url);
  }

  disconnect = () => {
    this.clearReconnectInterval();
    this.ws?.close();
    this.clearPingInterval();
    this.clearConnectionTimeout();
  };

  sendMessage(message: WSMessage) {
    if (this.isOpen()) {
      this.ws?.send(JSON.stringify(message));
    }
  }

  private handleBrokenConnection = () => {
    this.clearReconnectInterval();
    this.disconnect();
    this.ws = null;
    // emit close event to ensure that the app will be notified
    this.emit('close', null);
    debug('handleBrokenConnection', {
      reconnectBaseIntervalMS
    });
    this.reconnectInterval = setTimeout(() => {
      this.connect(this.url);
    }, reconnectBaseIntervalMS);
  };
}
