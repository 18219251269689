import { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { queuesState } from '../../atoms/queues-state';
import { ActionItem } from '../../components/action-panel/action-item';
import { showAppError } from '../../components/app-notification';
import { BasePageTemplate } from '../../components/base-page-template';
import { t } from '../../locales/i18n';
import { sizes } from '../../themes/sizes';
import {
  WSMessages,
  WSRemoveTicketResponse,
  WS_REMOVE_TICKET_RESPONSE
} from '../../web-socket/messages';
import { WSContext } from '../../ws-context/ws-context';

const Container = styled.section``;

const InfoPanel = styled.section`
  display: grid;
  justify-content: center;
  gap: ${sizes.padding.huge};
  margin: ${sizes.padding.huge};
`;

const InfoTextPanel = styled.span`
  text-align: center;
`;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SharingInfoPageProps {}
export function LeaveQueuePage(props: SharingInfoPageProps) {
  const navigate = useNavigate();
  const { sendWSMessage, socketIsOpen, subscribeMessage, unsubscribeMessage } =
    useContext(WSContext);
  const { queueId } = useParams();
  useEffect(() => {
    if (queueId && socketIsOpen) {
      sendWSMessage(WSMessages.getQueueDetail(queueId));
    }
  }, [queueId, sendWSMessage, socketIsOpen]);
  const queues = useRecoilValue(queuesState);
  const queue = queues.find((q) => q.id === queueId);
  const handleLeaveQueue = (queueId: string | undefined) => {
    if (queueId) {
      sendWSMessage(WSMessages.removeTicket(queueId));
    }
  };
  useEffect(() => {
    subscribeMessage(
      WS_REMOVE_TICKET_RESPONSE,
      (msg: WSRemoveTicketResponse) => {
        console.log(msg);
        if (msg.result === 'error') {
          showAppError(msg.errorCode);
        } else {
          navigate(`/queues/${queueId}`, { replace: true });
        }
      }
    );
    return () => {
      unsubscribeMessage(WS_REMOVE_TICKET_RESPONSE);
    };
  }, [navigate, queueId, subscribeMessage, unsubscribeMessage]);
  return (
    <BasePageTemplate
      title={
        <div>
          {t('screen.leaveQueue.title')} - {queue?.serviceProviderName}
        </div>
      }
      actionContent={
        <ActionItem
          onClick={() => handleLeaveQueue(queue?.id)}
          titleKey="buttons.leaveQueue"
        />
      }
    >
      <Container>
        <InfoPanel>
          <InfoTextPanel>{t('screen.leaveQueue.message1')}</InfoTextPanel>
          <InfoTextPanel>{t('screen.leaveQueue.message2')}</InfoTextPanel>
        </InfoPanel>
      </Container>
    </BasePageTemplate>
  );
}
