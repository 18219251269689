import { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { queuesState } from '../../atoms/queues-state';
import { sharingItemsState } from '../../atoms/sharing-items-state';
import { userProfileState } from '../../atoms/user-profile-state';
import { ActionItem } from '../../components/action-panel/action-item';
import {
  showAppError,
  showAppSuccess
} from '../../components/app-notification';
import { BasePageTemplate } from '../../components/base-page-template';
import { t } from '../../locales/i18n';
import { sharingItemsSelector } from '../../selectors/sharing-items-selector';
import { sizes } from '../../themes/sizes';
import {
  WSMessages,
  WSRemoteCheckinResponse,
  WS_REMOTE_CHECKIN_RESPONSE
} from '../../web-socket/messages';
import { WSContext } from '../../ws-context/ws-context';
import { SharingInfoItems } from './components/sharing-info-items';

const Container = styled.section``;

const ProviderInfoPanel = styled.section`
  display: grid;
  gap: ${sizes.padding.medium};
  margin: ${sizes.padding.big} 0;
  text-align: center;
`;
const NoSharedItemsPanel = styled.section`
  display: grid;
  margin: ${sizes.padding.big} 0;
  text-align: center;
`;

export function RemoteCheckinPage() {
  const { queueId } = useParams();
  const navigate = useNavigate();
  const { sendWSMessage, subscribeMessage, unsubscribeMessage } =
    useContext(WSContext);

  const queues = useRecoilValue(queuesState);
  const queue = queues.find((q) => q.id === queueId);

  const userProfile = useRecoilValue(userProfileState);
  const sharingInfo = useRecoilValue(sharingItemsState);
  const { sharingItems } = sharingItemsSelector(userProfile);
  const sharingInfoNotEmpty =
    sharingInfo?.email || sharingInfo?.phoneNumber || sharingInfo?.fullName;
  const handleRemoteCheckin = () => {
    if (queueId) {
      sendWSMessage(WSMessages.remoteCheckin(queueId, sharingInfo));
    }
  };
  useEffect(() => {
    subscribeMessage(
      WS_REMOTE_CHECKIN_RESPONSE,
      (msg: WSRemoteCheckinResponse) => {
        console.log(msg);
        if (msg.result === 'error') {
          showAppError(msg.errorCode);
        } else {
          navigate(`/queues/${queueId}`, { replace: true });
        }
      }
    );
    return () => {
      unsubscribeMessage(WS_REMOTE_CHECKIN_RESPONSE);
    };
  }, [navigate, queueId, subscribeMessage, unsubscribeMessage]);
  return (
    <BasePageTemplate
      title={`${t('screen.remoteCheckin.title')} - ${
        queue?.serviceProviderName
      }`}
      actionContent={
        <ActionItem
          primary
          onClick={() => handleRemoteCheckin()}
          iconName="save"
          titleKey="buttons.remoteCheckin"
        />
      }
    >
      <Container>
        <ProviderInfoPanel>
          <h3>
            {queue?.serviceProviderName} {queue?.name}
          </h3>
          <address>{queue && queue.locationShortAddress}</address>
        </ProviderInfoPanel>
        {sharingInfoNotEmpty && (
          <SharingInfoItems
            panelTitle={t('screen.remoteCheckinScreen.sharingInfoTitle')}
            items={sharingItems}
            readOnly={true}
          />
        )}
        {!sharingInfoNotEmpty && (
          <NoSharedItemsPanel>
            <span>{t('screen.remoteCheckinScreen.noSharedItems')}</span>
          </NoSharedItemsPanel>
        )}
      </Container>
    </BasePageTemplate>
  );
}
