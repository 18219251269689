import { useContext, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import { queuesState } from '../atoms/queues-state';
import { userProfileState } from '../atoms/user-profile-state';
import { WSMessages } from '../web-socket/messages';
import { WSContext } from '../ws-context/ws-context';
import { update } from 'rambda';
import { QueueModel } from '../types/models/queue-model';

export const PageLayout = styled.section`
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
`;

export const MainContentLayout = styled.section`
  display: grid;
`;

export function AppLayout() {
  const setQueuesState = useSetRecoilState(queuesState);
  const queues = useRecoilValue(queuesState);
  const setUserProfileState = useSetRecoilState(userProfileState);
  const { sendWSMessage, socketIsOpen, subscribeMessage, unsubscribeMessage } =
    useContext(WSContext);

  useEffect(() => {
    console.log('useEffect getMyQueues', socketIsOpen);
    if (!socketIsOpen) {
      return;
    }
    sendWSMessage(WSMessages.getMyQueues());
  }, [sendWSMessage, socketIsOpen]);

  useEffect(() => {
    if (!socketIsOpen) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    subscribeMessage('qmob/get-my-queues-response', (msg: any) => {
      setQueuesState(msg.queues);
      setUserProfileState(msg.customer);
    });
    return () => {
      unsubscribeMessage('qmob/get-my-queues-response');
    };
  }, [
    sendWSMessage,
    setQueuesState,
    setUserProfileState,
    socketIsOpen,
    subscribeMessage,
    unsubscribeMessage
  ]);

  useEffect(() => {
    if (!socketIsOpen) {
      return;
    }
    subscribeMessage(
      'qmob/get-queue-detail-response',
      (msg: { queue: QueueModel }) => {
        const inputQueue = msg.queue;
        const idx = queues.findIndex((q) => q.id === inputQueue.id);
        if (idx >= 0) {
          const newState = update(idx, inputQueue)(queues);
          setQueuesState(newState);
        }
      }
    );
    return () => {
      unsubscribeMessage('qmob/get-queue-detail-response');
    };
  }, [
    queues,
    sendWSMessage,
    setQueuesState,
    setUserProfileState,
    socketIsOpen,
    subscribeMessage,
    unsubscribeMessage
  ]);

  return <Outlet />;
}
