import { atom } from 'recoil';
import { SharingInfoState } from '../types/models/sharing-info-state';

export const sharingItemsState = atom<SharingInfoState>({
  key: 'SharingItemsState',
  default: {
    fullName: null,
    phoneNumber: null,
    email: null
  }
});
