import { QueueStatus } from '../../types/models/queue-model';
import { DoorOpen, DoorClosed } from '@styled-icons/remix-line';
import styled from 'styled-components';
import { colors } from '../../themes/colors';
import { sizes } from '../../themes/sizes';
import { T } from 'ramda';
import { t } from '../../locales/i18n';

const StatusOpenIcon = styled(DoorOpen)`
  width: ${sizes.icon.small};
  height: ${sizes.icon.small};
  fill: ${colors.success};
`;
const StatusClosedIcon = styled(DoorClosed)`
  width: ${sizes.icon.small};
  height: ${sizes.icon.small};
  fill: ${colors.error};
`;

const QueueStatusContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: start;
`;

const StatusTitle = styled.span<{ color: string }>`
  color: ${(props) => props.color};
  display: grid;
  grid-auto-flow: column;
  gap: 1em;
`;
export interface QueueItemStatusProps {
  status: QueueStatus;
  titleSuffix?: string;
}

export function QueueItemStatus(props: QueueItemStatusProps) {
  const isOpen = props.status === 'open';
  const finalStatus = isOpen ? 'open' : 'closed';
  const color = isOpen ? colors.success : colors.error;
  return (
    <QueueStatusContainer>
      {isOpen && <StatusOpenIcon />}
      {!isOpen && <StatusClosedIcon />}
      <StatusTitle color={color}>
        <span>{t(`statuses.queueStatus.${finalStatus}`)}</span>
        <span>{props.titleSuffix}</span>
      </StatusTitle>
    </QueueStatusContainer>
  );
}
