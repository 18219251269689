import { Link } from 'react-router-dom';
import styled from 'styled-components';

import LogoImage from '../assets/images/logo.png';
import ProfileImage from '../assets/images/profile.png';
import { t } from '../locales/i18n';
import { colors } from '../themes/colors';
import { sizes } from '../themes/sizes';

const NavBarContainer = styled.section`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: ${sizes.padding.medium};
  padding: ${sizes.padding.big};
  padding-bottom: 0;
`;

const Logo = styled.div`
  background-image: url('${LogoImage}');
  background-size: cover;
  width: ${sizes.icon.medium};
  height: ${sizes.icon.medium};
`;

const BrandTitle = styled.h1`
  font-size: ${sizes.fontSizes.massive};
  font-weight: 400;
  color: ${colors.secondary};
`;

const Profile = styled.div`
  background-image: url('${ProfileImage}');
  background-size: cover;
  width: ${sizes.icon.medium};
  height: ${sizes.icon.medium};
`;

const ProfileButton = styled(Link)`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: ${sizes.padding.medium};
  font-size: ${sizes.fontSizes.big};
  border: ${sizes.borderWidth.thin} solid ${colors.grey};
  padding: ${sizes.padding.medium};
  border-radius: ${sizes.borderRadius.normal};
`;

export function NavBar() {
  return (
    <NavBarContainer>
      <Link to="/">
        <Logo />
      </Link>
      <BrandTitle>NextinQ</BrandTitle>
      <ProfileButton to="/user-profile">
        <Profile />
        <span>{t('components.userProfileButton.myProfile')}</span>
      </ProfileButton>
    </NavBarContainer>
  );
}
