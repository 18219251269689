import styled from 'styled-components';
import { sizes } from '../../../../themes/sizes';

export const InputGroup = styled.section`
  display: grid;
  gap: ${sizes.padding.big};
  label {
    display: grid;
    grid-template-columns: 3fr 4fr;
  }
`;
