import { useState } from 'react';
import styled from 'styled-components';
import { colors } from '../themes/colors';
import { sizes } from '../themes/sizes';

const Container = styled.section`
  display: grid;
  grid-template-rows: auto 1fr;
  justify-content: stretch;
  align-content: stretch;
  height: 100%;
  gap: ${sizes.padding.huge};
`;

const ResultsRow = styled.section`
  font-size: 3.5rem;
  letter-spacing: 1rem;
  margin-left: 1rem;
  text-align: center;
  color: ${colors.primary};
  font-weight: bold;
  font-family: Oswald;
`;

const NumPadPanel = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  align-items: center;
  justify-items: center;
  gap: ${sizes.padding.small};
  padding: ${sizes.padding.big};
  background-color: ${colors.greyWhite};
`;

const KeyWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${colors.white};
  display: grid;
  align-items: center;
  justify-items: center;
  font-size: 2.5rem;
  font-family: Oswald;
  border-radius: ${sizes.borderRadius.normal};
`;

export function Key({
  char,
  onClick
}: {
  char: string;
  onClick: (char: string) => void;
}) {
  return <KeyWrapper onClick={() => onClick(char)}>{char}</KeyWrapper>;
}

export interface NumPadProps {
  inputLength: number;
  onEnter: (code: string) => void;
}

export function NumPad(props: NumPadProps) {
  const { inputLength, onEnter } = props;
  const [result, setResult] = useState('');

  const appendChar = (char: string) => {
    if (result.length < inputLength) {
      const newResult = result + char;
      setResult(newResult);
      if (newResult.length === inputLength) {
        onEnter(newResult);
      }
    }
  };

  const removeChar = () => {
    if (result.length > 0) {
      const newResult = result.substring(0, result.length - 1);
      setResult(newResult);
    }
  };

  return (
    <Container>
      <ResultsRow>{result.padStart(props.inputLength, '-')}</ResultsRow>
      <NumPadPanel>
        <Key char="1" onClick={(char) => appendChar(char)} />
        <Key char="2" onClick={(char) => appendChar(char)} />
        <Key char="3" onClick={(char) => appendChar(char)} />

        <Key char="4" onClick={(char) => appendChar(char)} />
        <Key char="5" onClick={(char) => appendChar(char)} />
        <Key char="6" onClick={(char) => appendChar(char)} />

        <Key char="7" onClick={(char) => appendChar(char)} />
        <Key char="8" onClick={(char) => appendChar(char)} />
        <Key char="9" onClick={(char) => appendChar(char)} />

        <Key char="<" onClick={() => removeChar()} />
        <Key char="0" onClick={(char) => appendChar(char)} />
      </NumPadPanel>
    </Container>
  );
}
